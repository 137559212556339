import clsx from "clsx"
import PropTypes from "prop-types"
import React, { useState } from "react"

const logoutLink = "/logout"
const findPractitionerLink = "/practitioner-search"
const listYourPracticeLink = "/lp/application"

const LinkList = ({ items }) => (
  <>
    {items.map((item) => (
      <a
        className="flex h-16 w-45vw items-center justify-start border-b border-solid border-black pl-2.5 text-base font-medium leading-4 text-white"
        key={item.name}
        href={item.href}>
        {item.name}
      </a>
    ))}
  </>
)

const MobileMenuContainer = ({ children }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const toggleShow = () => {
    setIsHovered(!isHovered)
  }

  return (
    <div
      className="flex h-12 w-12"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={toggleShow}>
      <i
        className={clsx("icon-arrow-down-see-more linear ml-2 flex items-center transition-transform duration-100", {
          "rotate-180": isHovered
        })}
      />
      <div className={clsx("absolute left-0 top-16 z-50 w-45vw flex-col bg-black", isHovered ? "flex" : "hidden")}>
        {children}
      </div>
    </div>
  )
}

const MobileMenu = ({ isLoggedIn, isPractitioner }) => {
  const DefaultLinks = [
    { name: "Home", href: "/" },
    { name: "Find Practitioners", href: findPractitionerLink },
    { name: "List Your Practice", href: listYourPracticeLink },
    { name: "Login", href: "/login" }
  ]

  const ClientLoggedInLinks = [
    { name: "Home", href: "/" },
    { name: "Find Practitioners", href: findPractitionerLink },
    { name: "List Your Practice", href: listYourPracticeLink },
    { name: "Payment Methods", href: "/payment_methods" },
    { name: "Your Bookings", href: "/appointments" },
    { name: "Your Reviews", href: "/my-reviews" },
    { name: "Settings", href: "/settings" },
    { name: "Logout", href: logoutLink }
  ]

  return (
    <>
      {isLoggedIn && isPractitioner ? null : (
        <MobileMenuContainer>
          {isLoggedIn ? <LinkList items={ClientLoggedInLinks} /> : <LinkList items={DefaultLinks} />}
        </MobileMenuContainer>
      )}
    </>
  )
}

MobileMenu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isPractitioner: PropTypes.bool.isRequired
}

export default MobileMenu
