document.addEventListener("DOMContentLoaded", function () {
  // Sometimes, using .js/remote loads requires us to fire App.refireLoad()
  // so all the JS we need works.
  //
  // Sometimes, this means that jQuery handlers can get attached multiple times to the
  // same objects. Usually this makes little difference as even if it runs multiple times
  // it has no problematic effect. Like the [data-icon] handler, or the handler that adds 'working'
  // class to a form button on submit. Even if it runs multiple times, it's just adding the 'working'
  // class a few times - which isn't an issue.

  // But click events that use class toggles cause issues, as having a double handler means
  // that toggle behavior runs twice, ie, it cancels itself out.

  // All such tricky controls are grouped here and constructed to first check on the element
  // if the handler was already bound once.

  // Togglers
  $("[data-toggle]").each(function () {
    if ($(this).data("toggleBound") != true) {
      $(this).click(function (e) {
        e.preventDefault()
        let targetSelector = $(this).attr("data-toggle")
        $(targetSelector).slideToggle()

        if ($(this).attr("data-remove")) {
          $(this).fadeOut()
          $("#close-booking-modal-button").removeClass("hidden")
        }

        if ($(this).attr("data-remove-book-btn")) {
          $("#book-session-button").fadeOut()
          $("#close-booking-modal-button").removeClass("hidden")
        }

        if ($(this).attr("data-restore")) {
          $("#close-booking-modal-button").addClass("hidden")
          $("#book-session-button").attr("style", "display:block")
        }
      })
      $(this).data("toggleBound", true)
    }
  })

  // Action Toggles
  $("[data-action-toggle]").each(function () {
    if ($(this).data("toggleBound") != true) {
      const el = $(this)
      const links = el.find("[data-toggler] a")
      $("[data-action-target]", el).hide()

      links.click(function (e) {
        e.preventDefault()

        if ($(this).hasClass("selected")) {
          $("[data-action-target]", el).hide()
          $(this).removeClass("selected")
        } else {
          links.removeClass("selected")
          $("[data-action-target]", el).hide()

          const action = $(this).attr("data-action")
          el.find(`[data-action-target][data-action=${action}]`).show()
          el.find(`[data-toggler] a[data-action=${action}]`).addClass("selected")
        }
      })
      $(this).data("toggleBound", true)
    }
  })

  // Dropdowns
  $(".dropdown button").each(function () {
    if ($(this).data("dropdownBound") != true) {
      $(this).click((e) => {
        e.preventDefault()
        $(this).closest(".dropdown").toggleClass("is-active")
      })
      $(this).data("dropdownBound", true)
    }
  })

  $(".dropdown .dropdown-trigger a").each(function () {
    if ($(this).data("dropdownBound") != true) {
      $(this).click((e) => {
        e.preventDefault()
        $(this).closest(".dropdown").toggleClass("is-active")
      })
      $(this).data("dropdownBound", true)
    }
  })

  // Navbar
  $(".navbar-link").each(function () {
    if ($(this).data("dropdownBound") != true) {
      $(this).click(function (e) {
        e.preventDefault()
        $(this).closest(".has-dropdown").toggleClass("is-active")
      })
      $(this).data("dropdownBound", true)
    }
  })

  $("a.navbar-burger").each(function () {
    if ($(this).data("toggleBound") != true) {
      $(this).click(function (e) {
        e.preventDefault()
        $(this).toggleClass("is-active")
        $(this).closest("nav").find(".navbar-menu").toggleClass("is-active")
      })
      $(this).data("toggleBound", true)
    }
  })
})
