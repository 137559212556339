import React from "react"
import { twMerge } from "tailwind-merge"

import HealMeTooltip from "./HealMeTooltip"
import { Label } from "./Inputs"

export const Radio = ({ className, ...rest }) => (
  <input className={twMerge("h-6 w-6 cursor-pointer accent-gray outline-none", className)} type="radio" {...rest} />
)

export const RadioWithLabel = ({ id, label, checked, disabledTooltipMessage, className, ...rest }) => {
  id ||= "radio-" + label.toLowerCase().replace(" ", "-")

  return (
    <div className={twMerge("flex items-center", className)} data-tooltip-id={id}>
      <Radio id={id} {...rest} checked={checked} className={`flex-none ${checked ? "accent-gray-dark" : ""}`} />
      <Label className={twMerge("mb-0 truncate pl-2 text-base", checked ? "" : "font-normal")} htmlFor={id}>
        {label}
      </Label>
      {rest.disabled && disabledTooltipMessage && (
        <HealMeTooltip id={id} place="bottom" content={disabledTooltipMessage} />
      )}
    </div>
  )
}
