import PropTypes from "prop-types"
import React from "react"
import { Tooltip } from "react-tooltip"

const HealMeTooltip = (props) => (
  <Tooltip
    style={{
      "--rt-color-dark": "rgb(var(--color-black))",
      zIndex: "9999",
      fontWeight: "bold",
      maxWidth: "18rem",
      borderRadius: "0.5rem",
      padding: "1.5rem"
    }}
    {...props}
  />
)

HealMeTooltip.propTypes = {
  id: PropTypes.string,
  anchorSelect: PropTypes.string,
  render: PropTypes.func,
  place: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  noArrow: PropTypes.bool,
  isOpen: PropTypes.bool
}

export default HealMeTooltip
