import React from "react"
import styled from "styled-components"

const Title = styled.h2`
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -1%;
  color: #0b3954;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    font-size: 21px;
    line-height: 27px;
  }
`
// DEPRECATED: use Typography instead
export default function DesktopTitle1({ content }) {
  return <Title>{content}</Title>
}
