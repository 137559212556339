import React from "react"
import styled from "styled-components"

const Title = styled.h3`
  font-family: inherit
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -1%;
  color: #0b3954;
  margin-bottom: 16px;
`
// DEPRECATED: use Typography instead
export default function DesktopTitle3({ content }) {
  return <Title>{content}</Title>
}
